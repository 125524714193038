<template>
  <div class="popup-add-excursion popup-flashmob-item">
    <div class="popup-add-excursion__wrapper">
      <Column :class="$viewport.desktop ? 'grid-6' : ''">
        <p class="popup-add-excursion__title">{{ params.title }}</p>
        <p class="popup-add-excursion__description" v-html="params.description"></p>
        <p class="popup-add-excursion__info"><strong>Формат:</strong> {{ params.format }}</p>
        <p class="popup-add-excursion__info">
          <strong>Место:</strong> {{ params.address }}
        </p>
        <p class="popup-add-excursion__info"><strong>Дата:</strong> {{ params.date }}</p>
        <p class="popup-add-excursion__info">
          <strong>Продолжительность:</strong> {{ params.duration }} минут
        </p>
        <p class="popup-add-excursion__info">
          <strong>Количество мест:</strong> {{ params.seats_number }}
        </p>
        <p class="popup-add-excursion__info">
          <strong>Организатор:</strong> {{ params.organization }}
        </p>
        <p class="popup-add-excursion__info">
          <strong>Телефон:</strong> {{ params.phone }}
        </p>
        <p class="popup-add-excursion__info" v-if="params.age_restrictions">
          <strong>Возрастные ограничения:</strong> {{ params.age_restrictions }}+
        </p>
        <p class="popup-add-excursion__info">
          <strong>Условия посещения:</strong> {{ params.conditions }}
        </p>
        <div
          class="popup-add-excursion__image"
          v-for="(imageSource, index) in params.images"
          :key="index"
        >
          <img :src="getImageUrl(imageSource)" :alt="`${params.title}[${index}]`">
        </div>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupFlashmobItem',
  data() {
    return {
      params: {},
    };
  },
  methods: {
    getImageUrl(path) {
      return `https://act.tomsk.life/storage/${path}`;
    },
  },
  created() {
    this.params = this.$popup.params;
  },
};
</script>

<style lang="scss" scoped>
  .popup-flashmob-item {
    .popup-add-excursion {
      &__wrapper {
        min-height: 60vh;
      }

      &__info {
        font-size: 16px;
        font-weight: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 0.8rem;
      }

      &__image {
        margin-bottom: 10px;

        img {
          width: 100%;
        }
      }
    }
  }
</style>
